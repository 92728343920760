.user-data-one-line {
    @include display-flex;
    @include align-items(center);
    &__avatar {
        background-color: $color-lighter;
        border-radius: 50%; }
    &__info-primary,
    &__info-secondary {
        font-size: 13px; }
    &__info-primary {
        color: $color-dark;
        margin: 0 .25rem 0 1rem; } }
