.dashboard-stat {
    @include display-flex;
    @include align-items(center);
    &__icon {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: #fff;
        @include display-flex;
        @include align-items(center);
        @include justify-content(center);
        svg {
            width: 20px;
            height: 20px; }
        &-wrapper {
            margin-right: 1rem; } }
    &__data {}
    &__unit {
        font-size: 32px; } }
