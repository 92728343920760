.daily-collection-table {
    &__heading {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 1rem; }
    &__actions {
        margin-bottom: 1rem;
        .ant-btn {
            &:not(:last-child) {
                margin-right: .5rem; } } } }
