.payment-source {
    margin-top: .5rem;
    .colored-title {
        font-size: 13px;
        margin-bottom: .25rem; }
    &__body {
        background-color: $color-lightest;
        padding: .5rem 1rem;
        border: 1px solid $color-lighter;
        border-radius: $radius-general; } }
