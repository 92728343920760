.receipt-entry-sheet {
    &__header {
        font-size: 16px;
        color: $color-primary;
        margin-bottom: 1rem; }
    &__row {
        @include display-flex;
        @include align-items(center);
        margin-bottom: .5rem;
        gap: $gutter; }
    &__col {
        &--left {
            @include flex(0 0 50%);
            max-width: 50%; }
        &--right {
            margin-left: auto; } }
    &__footer {
        margin-top: 1.5rem;
        .receipt-entry-sheet__row {
            margin-bottom: 0; } } }
