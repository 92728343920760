.search-member-modal {
    &__info {
        @include display-flex;
        &-mid {
            margin-left: auto; }
        &-right {
            margin-left: 1rem;
            @include display-flex;
            @include flex-direction(column);
            .ant-btn {
                &:not(:last-child) {
                    margin-bottom: .3125rem; } } } }
    &__table {
        margin-top: 1rem;
        &-heading {
            @include display-flex;
            @include align-items(center); }
        &-action {
            margin-left: auto; } } }
