.general-account {
    &-form {
        .ant-form {
            .ant-form-item {} } }
    &__form-group {
        position: fixed;
        right: 0;
        bottom: 0;
        left: 200px;
        background-color: #FFFFFF;
        padding: .5rem 1rem;
        @include display-flex;
        @include align-items(center);
        @include justify-content(center);
        @include box-shadow($box-shadow-block); } }
