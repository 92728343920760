.side-nav {
    &__logo {
        height: 64px;
        padding: 12px;
        margin: 8px;
        border-radius: 6px;
        background: rgba(255, 255, 255, 0.95);
        border-top: 1px solid $color-secondary;
        border-left: 1px solid $color-secondary;
        @include display-flex;
        img {
            width: 100%;
            max-width: 84px; } }
    &__menu {
        border-top: 1px solid $color-light;
        font-size: 15px;
        a {
            display: block;
            // padding: 0 1.5rem
            &.active {
                background-color: $color-highlight; } }
        &--top-level {
            padding: 0 20px;
            margin-top: 10px; }

        &-item {
            &--primary {
                margin-top: 0 !important; } }
        &.ant-menu-inline-collapsed {
            a,
            .ant-menu-item {
                padding: 0; }
            .ant-menu {
                &-item {
                    @include display-flex;
                    @include align-items(center);
                    @include justify-content(center); }
                &-title-content {
                    display: none; } } } } }
