.receipt-entry-note {
    margin: .5rem 0;
    &__row {
        @include display-flex; }
    &__col {
        &--left {
            @include flex(0 0 50%);
            max-width: 50%; }
        &--right {
            margin-left: auto; } } }
