.app-layout-header {
    padding: 0 16px;
    max-height: 44px;
    @include display-flex;
    @include align-items(center);
    color: #FFFFFF;
    &__date-time {
        font-size: 12px;
        margin-right: 3rem; }
    &__username {}
    &__left {
        font-size: 14px;
        // color: lighten($color-primary, 10%)
        text-transform: uppercase;
        font-weight: bold; }
    &__right {
        margin-left: auto;
        @include display-flex;
        @include align-items(center);
        .ant-avatar {
            background-color: $color-lightest;
            margin-left: 1rem;
            cursor: pointer;
            width: 26px;
            height: 26px; } } }
