.auth {
    background-color: $color-lightest;
    padding: 4rem 0;
    min-height: 100vh;
    @include display-flex;
    @include align-items(center);
    @include justify-content(center);
    &__wrapper {
        background-color: #FFFFFF;
        max-width: 80%;
        border: 1px solid $color-lighter;
        border-radius: $radius-alt;
        overflow: hidden;
        @include flex(1); } }
