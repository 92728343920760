.login {
    &__wrapper {
        .ant-row {
            height: 100%; } }
    &__each {
        padding: 5rem 2rem;
        @include display-flex;
        @include align-items(center);
        @include justify-content(center);
        &--left {
            // background-repeat: no-repeat
            background-color: $color-primary;
            background-repeat: no-repeat;
            background-image: url('../../assets/images/svg/bar-round.svg'), url('../../assets/images/svg/bar-round.svg'), url('../../assets/images/svg/bar-round.svg'), url('../../assets/images/svg/bar-round.svg'), url('../../assets/images/svg/message-and-flowers.svg');
            background-position: 10% 12%, 20% 18%, 90% -10%, 80% 50%, 80% 100%;
            width: 100%;
            height: 100%; }
        &--right {
            max-width: 80%;
            height: 100%;
            margin-right: auto;
            margin-left: auto; } } }
