.dashboard-header {
    .block-general {
        @include display-flex;
        @include align-items(start);
        @include flex-direction(column);
        @media only screen and (min-width: #{$md-screen + 1}px) {
            @include align-items(center);
            @include flex-direction(row); }
        @media only screen and (min-width: #{$lg-screen + 1}px) {
            background-image: url('../../../assets/images/svg/square-round-tilted.svg'), url('../../../assets/images/svg/bar-round-secondary-tilted.svg'), url('../../../assets/images/svg/bar-round-tilted.svg');
            background-repeat: no-repeat;
            background-position: 92% 120%, 94% 120%, 98% 120%; } }
    &__title {
        font-size: 24px;
        font-weight: 600;
        text-transform: capitalize;
        @include display-flex;
        @include align-items(start);
        flex-direction: column;
        margin-top: 10px;
        @media only screen and (min-width: #{$md-screen + 1}px) {
            margin-left: 2rem;
            margin-top: 0; }
        @media only screen and (min-width: #{$lg-screen + 1}px) {
            @include align-items(center);
            flex-direction: row; } } }
