.member-registration-header {
    @include display-flex;
    @include align-items(center);
    padding-top: 3rem;
    padding-bottom: 2.5rem;
    &__login {
        font-size: 13px;
        color: $color-secondary; }
    &__title {
        font-size: 22px;
        color: $color-primary;
        &-wrapper {
            margin-left: auto; } } }
